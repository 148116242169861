import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import react from "react";
import "./Header.css"
import { NavLink } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
// import { Button } from "bootstrap";
import { FaBars } from 'react-icons/fa'
import { ImCross } from 'react-icons/im'
import { useState } from "react";
import Button from 'react-bootstrap/Button';
import { auth } from  '../../config/firebaseConfig';
import useAuth from '../../Login/useAuth';
import ProfileDropdown from '../../Login/ProfileDropdown';

export default function Header() {
  const [Mobile, setMobile] = useState(false)


  const { currentUser, logout } = useAuth(); // Assuming you have a hook for Firebase authentication

  const handleLogout = async () => {
    try {
      await logout(); // Assuming your logout function handles the sign-out
      alert('Logged out successfully');
    } catch (error) {
      alert(error.message);
    }
  };



  return (

    <div className='nav-barhead'>


 


      <Navbar expand="lg" className="bg-body-tertiary ">
        <Container>
          <Navbar.Brand href="#"><div className="logo">
            <img src="../../img/logov.png"></img>
            {/* <img src="../../img/logo.webp"></img> */}
            <p className="prosus">"Professionals with Sustainability Mindset”</p>

          </div></Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">

            <div className='nave-menu'>
              <Nav className="me-auto">


    
                <NavDropdown title={<a href="#home"><NavLink to="/">Zha Vision</NavLink></a>} id="basic-nav-dropdown">
                <NavDropdown.Item> <a href=""><NavLink to="/Aboutus">About Us</NavLink></a></NavDropdown.Item>
                  <NavDropdown.Item> <a href=""><NavLink to="/How-Zha-Use-CSR-Funds">How Zha Use CSR Funds</NavLink></a></NavDropdown.Item>

                  <NavDropdown.Item> <a href=""><NavLink to="/Why-Zha-360-Sustainability">Why Zha 360 Sustainability</NavLink></a></NavDropdown.Item>

                  <NavDropdown.Item> <a href=""><NavLink to="/Zha-Common-Humans-COPS">Zha Common Humans COP's</NavLink></a></NavDropdown.Item>
                  <NavDropdown.Item> <a href=""><NavLink to="/Zha-Establishment-Story">Zha Establishment Story</NavLink></a></NavDropdown.Item>

                  
                  
                </NavDropdown>





                <NavDropdown title={<a href=""><NavLink to="/about">How Zha Functions</NavLink></a>} id="basic-nav-dropdown">
                  <NavDropdown.Item ><a href=""><NavLink to="/Role-of-Zha-Sustainability-Club">Role of Zha Sustainability Club</NavLink></a></NavDropdown.Item>
                  <NavDropdown.Item ><a href=""><NavLink to="/structure">Zha Org Structure</NavLink></a></NavDropdown.Item>

                  <NavDropdown.Item> <a href=""><NavLink to="/Zha-Sustainability-Club-Membership">Zha Board Membership</NavLink></a></NavDropdown.Item>

                  <NavDropdown.Item> <a href=""><NavLink to="/Sponsors">Zha Sponsorship Program</NavLink></a></NavDropdown.Item>
                  <NavDropdown.Item> <a href=""><NavLink to="/Zha-sponsor-scheme">Zha Sponsors Scheme</NavLink></a></NavDropdown.Item>
                  <NavDropdown.Item> <a href=""><NavLink to="/ZHA-Sustainability-Practitioners-Club-Memberships">ZHA Sustainability Practitioners Club Memberships</NavLink></a></NavDropdown.Item>
                 

                </NavDropdown>
 


                <NavDropdown title={<a href="" onClick={(e) => e.preventDefault()}><NavLink to="/The-Zha-Sustainability-Practitioners-Club">Zha Sustainability Club</NavLink></a>} id="basic-nav-dropdown">
                  <NavDropdown.Item> <a href=""><NavLink to="/Zha-Students-Sustainability-Club">Zha Students Sustainability Club</NavLink></a></NavDropdown.Item>
                  <NavDropdown.Item> <a href=""><NavLink to="/Zha-Professionals-Sustainability-Club">Zha Professionals Sustainability Club</NavLink></a></NavDropdown.Item>
                  <NavDropdown.Item> <a href=""><NavLink to="/Membership-Cost">Membership Program</NavLink></a></NavDropdown.Item>
                  <NavDropdown.Item> <a href=""><NavLink to="/welcome-NRI">Zha NRI Connect</NavLink></a></NavDropdown.Item>
                 

                  <NavDropdown title={<a href=""><NavLink to="/Zha-Sports-Training-Club">Zha Wellness Club</NavLink></a>} id="basic-nav-dropdown">
                    <NavDropdown.Item> <a href=""><NavLink to="/Zha-Cycle-Club">Zha Cycling Club</NavLink></a></NavDropdown.Item>
                    <NavDropdown.Item> <a href=""><NavLink to="/Zha-Athletics-Training-Club">Zha Athletics Training Club</NavLink></a></NavDropdown.Item>
                    <NavDropdown.Item> <a href=""><NavLink to="/Zha-Indoor-Training-Club">Zha Indoor Training Club</NavLink></a></NavDropdown.Item>
                    <NavDropdown.Item> <a href=""><NavLink to="/Zha-Volleyball-Training-Club">Zha Volleyball Training Club</NavLink></a></NavDropdown.Item>
                  </NavDropdown>
                </NavDropdown>



                
                <NavDropdown title={<a href=""><NavLink to=" ">Our Offerings</NavLink></a>} id="basic-nav-dropdown">
                <NavDropdown.Item ><a href=""><NavLink to="/Rural-Primary-Health-Care">Zha Rural Primary Health Care</NavLink></a></NavDropdown.Item>
                  <NavDropdown.Item> <a href=""><NavLink to="/Gender-Equality-and-Women-Empowerment">Zha Gender Equality and Women Empowerment</NavLink></a></NavDropdown.Item>
                  <NavDropdown.Item> <a href=""><NavLink to="/Disaster-Relief">Zha Disaster Relief</NavLink></a></NavDropdown.Item>
                  <NavDropdown.Item> <a href=""><NavLink to="/Environment">Zha Environment</NavLink></a></NavDropdown.Item>
                  <NavDropdown.Item> <a href=""><NavLink to="/Zha-Free-Mind-Wellness-Counselling">Zha Free Mind Wellness Counselling</NavLink></a></NavDropdown.Item>   <NavDropdown.Item> <a href=""><NavLink to="/Zha-Common-Humans-COPs">Zha Common Man COPS's</NavLink></a></NavDropdown.Item>
                  <NavDropdown.Item> <a href=""><NavLink to="/Zha-Free-Coaching-Center-for-Rural-Students">Zha Free Coaching Center for Rural Students</NavLink></a></NavDropdown.Item>
                  <NavDropdown.Item> <a href=""><NavLink to="/Pathfinder-Course">Zha Pathfinder Course for Public</NavLink></a></NavDropdown.Item>
                  <NavDropdown.Item ><a href=""><NavLink to="/zha-farmers-transformation">Zha Farmers Transformation</NavLink></a></NavDropdown.Item>
                  <NavDropdown.Item> <a href=""><NavLink to="/Zha-Ecofriendly-Certification">Zha Eco-Friendly Certification</NavLink></a></NavDropdown.Item>
                  <NavDropdown.Item> <a href=""><NavLink to="/Zha-Recognition-Awards">Zha Recognition Awards</NavLink></a></NavDropdown.Item>
                  <NavDropdown.Item> <a href=""><NavLink to="/Successful-NRI-Free-Virtual-Nation-Social-Services-Program">Successful NRI Free Virtual Nation Social Services Program</NavLink></a></NavDropdown.Item>
               
                
                </NavDropdown>
 

                {/* <Nav.Link><a href=""><NavLink to="/welcome-NRI">Zha NRI Connect</NavLink></a></Nav.Link> */}

                <NavDropdown title={<a href=""><NavLink to="/ResearchandDevelopement">Zha Sustainability R&D </NavLink></a>} id="basic-nav-dropdown">

                  <NavDropdown.Item> <a href=""><NavLink to="/Zha-Scaling-Sustainability-Maturity-Framework">Zha Scaling Sustainability Maturity Framework</NavLink></a></NavDropdown.Item>

                  <NavDropdown.Item> <a href=""><NavLink to="/Zha-Ecofriendly-Certification">Zha Ecofriendly Certification</NavLink></a></NavDropdown.Item>
                  <NavDropdown.Item> <a href=""><NavLink to="/Zha-Sustainable-Certification-Process">Zha Sustainability Certification Process</NavLink></a></NavDropdown.Item>





                  <NavDropdown title={<a href=""><NavLink to="/Zha-Sustainability-Summit-2024">Zha Sustainability Summit 2024 </NavLink></a>} id="basic-nav-dropdown">
                    {/* <NavDropdown.Item > <a href=""><NavLink to="/Zha-Sustainability-Summit-2024">Zha Sustainability Summit 2024</NavLink></a></NavDropdown.Item> */}

                    <NavDropdown.Item > <a href=""><NavLink to="Zha-Farm-Management-Services">Zha Farm Management Services</NavLink></a></NavDropdown.Item>
                    <NavDropdown.Item > <a href=""><NavLink to="/Zha-Agri-Sales-Solutions">Zha Agri Sales Solutions</NavLink></a></NavDropdown.Item>
                    <NavDropdown.Item > <a href=""><NavLink to="/Zha-Sustainability-Summit-Goals">Zha Sustainability Summit Goals</NavLink></a></NavDropdown.Item>
                    <NavDropdown.Item > <a href=""><NavLink to="/Profitable-for-producers-and-consumers">Profitable for producers and consumers</NavLink></a></NavDropdown.Item>


                  </NavDropdown>
                  <NavDropdown.Item > <a href=""><NavLink to="/Zha-Sustainability-Assessment-Framework">Zha Sustainability Assessment Framework</NavLink></a></NavDropdown.Item>
                  
                  <NavDropdown.Item > <a href=""><NavLink to="/Export">Zha Exports and Bi-Products creation</NavLink></a></NavDropdown.Item>
                  <NavDropdown.Item> <a href=""><NavLink to="/sustainable-energy-solutions">Zha Sustainable Energy Solutions</NavLink></a></NavDropdown.Item>
                </NavDropdown>

                <NavDropdown title={<a href=""><NavLink to="/Socialservices">Zha Services</NavLink></a>} id="basic-nav-dropdown">

                  {/* <NavDropdown.Item ><a href=""><NavLink to="/Rural-Primary-Health-Care">Zha Rural Primary Health Care</NavLink></a></NavDropdown.Item>
                  <NavDropdown.Item> <a href=""><NavLink to="/Gender-Equality-and-Women-Empowerment">Zha Gender Equality and Women Empowerment</NavLink></a></NavDropdown.Item>
                  <NavDropdown.Item> <a href=""><NavLink to="/Disaster-Relief">Zha Disaster Relief</NavLink></a></NavDropdown.Item>
                  <NavDropdown.Item> <a href=""><NavLink to="/Environment">Zha Environment</NavLink></a></NavDropdown.Item>
                  <NavDropdown.Item> <a href=""><NavLink to="/Zha-Free-Mind-Wellness-Counselling">Zha Free Mind Wellness Counselling</NavLink></a></NavDropdown.Item>
                  <NavDropdown.Item> <a href=""><NavLink to="/Successful-NRI-Free-Virtual-Nation-Social-Services-Program">Successful NRI Free Virtual Nation Social Services Program</NavLink></a></NavDropdown.Item>
                  <NavDropdown.Item> <a href=""><NavLink to="/Zha-Common-Humans-COPs">Zha Common Man COPS's</NavLink></a></NavDropdown.Item>
                  <NavDropdown.Item> <a href=""><NavLink to="/Zha-Free-Coaching-Center-for-Rural-Students">Zha Free Coaching Center for Rural Students</NavLink></a></NavDropdown.Item>
                  <NavDropdown.Item> <a href=""><NavLink to="/Pathfinder-Course">Zha Pathfinder Course for Public</NavLink></a></NavDropdown.Item>
                  <NavDropdown.Item ><a href=""><NavLink to="/zha-farmers-transformation">Zha Farmers Transformation</NavLink></a></NavDropdown.Item>
                  <NavDropdown.Item> <a href=""><NavLink to="/Zha-Ecofriendly-Certification">Zha Eco-Friendly Certification</NavLink></a></NavDropdown.Item>
                  <NavDropdown.Item> <a href=""><NavLink to="/Zha-Recognition-Awards">Zha Recognition Awards</NavLink></a></NavDropdown.Item>
                   */}

                  
                  <NavDropdown.Item> <a href=""><NavLink to="/Zha-Lifetime-Achievement-Award">Zha Lifetime Achievement Award</NavLink></a></NavDropdown.Item>
                  <NavDropdown.Item> <a href=""><NavLink to="/Zha-Internship-Program">Zha Free Internship Program</NavLink></a></NavDropdown.Item>
                  <NavDropdown.Item> <a href=""><NavLink to="/Event">Zha Initiatives</NavLink></a></NavDropdown.Item>
                  <NavDropdown.Item> <a href=""><NavLink to="/Zha-Wellness-Program">Zha Wellness Program</NavLink></a></NavDropdown.Item>

                  <NavDropdown.Item> <a href=""><NavLink to="/Zha-Global-Community-of-Practice">Zha Global Community of Practice</NavLink></a></NavDropdown.Item>
                 
                 
                   <NavDropdown.Item> <a href=""><NavLink to="/Professionals-Awarding-Program">Professionals Awarding Program</NavLink></a></NavDropdown.Item>
                
                  <NavDropdown.Item> <a href=""><NavLink to="/Zha-Clean-Blue-Beach">Zha Clean Blue Beach</NavLink></a></NavDropdown.Item>
                 
                  
                </NavDropdown>

 
                
                <Nav.Link><a href=""><NavLink to="/Contact">Contact us</NavLink></a></Nav.Link>
<div className='profileicon'>
                 <ProfileDropdown />  
  
                 </div> 
                 {/* <div className="Donateus">
<NavLink to="/Donate"> <button className="donate-buttonheadermob">Donate </button></NavLink>
        </div>    */}
 
        
              </Nav>
              

            </div>

          </Navbar.Collapse>


          {/* <div className="Donateus">
<NavLink to="/Donate"> <button className="donate-buttonheader">Donate </button></NavLink>
        </div>   */}



        </Container>
      </Navbar>


     
 
    </div>
  );
}